<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    filtersFields: Object,
});
const emit = defineEmits(['filterInputs']);

const filterInputs = reactive(props.filtersFields);


watch(() => filterInputs, (newVal) => {
    emit ('filterInputs', newVal);
}, {deep: true});

</script>

<template>
    <div :class="props.class">
        <div>
            <p class="mb-6 font-medium">Настройка отображения фильтра виджета</p>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div v-for="[key, value] of Object.entries(filterInputs)" :key="key">
                    <div class="flex gap-2 items-center mb-2">
                        <Checkbox v-model="value.checked" :name="value.slug" :binary="true"/>
                        <span>{{ value.name }}</span>
                    </div>
                    <Dropdown
                        v-model="value.selected"
                        :options="value.options"
                        optionLabel="name"
                        :placeholder="'Выберите ' + value.name"
                        class="w-full md:w-14rem"
                    />
                </div>
        </div>
    </div>
</template>