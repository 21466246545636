<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 6.25V8.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 12V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 6.25V8.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 16V18" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 16V18" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 7.25H18" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 12H22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.5604 20.33C21.0004 21.3 19.9504 21.95 18.7504 21.95C16.9604 21.95 15.8604 20.15 15.8604 20.15M15.9304 17.09C16.4904 16.11 17.5404 15.46 18.7504 15.46C20.9204 15.46 22.0004 17.26 22.0004 17.26M22.0004 15.25V17.25H20.0004M17.8604 20.14H15.8604V22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>