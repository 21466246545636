<template>
  <div class="text-#212B36 flex flex-col gap-12 pb-4 pr-4 overflow-y-auto">
    <div>
      <div class="flex flex-col gap-12">
        <div>
          <h4 class="font-semibold mb-6">{{$t('integrations.title')}}</h4>
          <div class="text-sm flex flex-col gap-4">
            <div v-for="integrationItem in listIntegrations" class="flex gap-2 items-center">
              <RadioButton :disabled="!integrationItem.active"  v-model="integration" :value="integrationItem.slug" :inputId="integrationItem.slug"/>
              <label :for="integrationItem.slug" class="cursor-pointer">{{integrationItem.title}}</label>
              <Chip v-tooltip.bottom="{
                pt:{
                  root:{
                    class:'text-sm min-w-[30rem] text-center'
                  }
                },
                value:$t('integrations.soon_description')
              }" v-if="!integrationItem.active" :label="$t('integrations.soon')" class="cursor-pointer" />
            </div>
          </div>
          <div class="mt-6">
            <AppBitrixModule :id="widgetId" v-show="integration === 'bitrix24'" />
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script setup>

import UploadDataApi from "~/components/img/buttons/UploadDataApi.vue";
import CategoryFunnelUser from "~/components/AppWidgetSetting/CategoryFunnelUser.vue";
import {useTenantStore} from "~/stores/useTenantStore";
import {useWidgetIntegrationStore} from "~/stores/useWidgetIntegrationStore";
import AppBitrixModule from "~/components/integrations/Types/AppBitrixModule.vue";

const {t} = useI18n();

const props = defineProps({
  widgetId:{
    type:String,
    required:true,
  }
})


const listIntegrations = ref([
  {
    title:t('integrations.list.bitrix24'),
    slug:'bitrix24',
    active:true,
  },
  {
    title:t('integrations.list.amo'),
    slug:'amo',
    active:false,
  },
  {
    title:t('integrations.list.1c'),
    slug:'1c',
    active:false,
  },
])

const integration = ref(null)


const emits = defineEmits(
    [

    ]
)

onBeforeMount(()=>{
  integration.value = listIntegrations.value.find(()=>true)?.slug;
})



onBeforeUnmount(()=>{

})


</script>