
type optionTabType = {
    title:'',
    slug:'',
    meta?:null,
}

export const optionsTab = <optionTabType[]>[
    {
        title:'Шахматка',
        slug:'chess',
        meta:1,
    },
    {
        title:'Расширенная шахматка',
        slug:'large_chess',
        meta:2,
    },
    {
        title:'Планировки',
        slug:'plan_list',
        meta:3,
    },
    {
        title:'Список',
        slug:'objects_list',
        meta:4,
    },
]

export const optionsTabMobile = <optionTabType[]>[
    {
        title:'Шахматка',
        slug:'chess',
        meta:1,
    },
    {
        title:'Планировки',
        slug:'plan_list',
        meta:3,
    },
    {
        title:'Список',
        slug:'objects_list',
        meta:4,
    },
]