<script setup lang="js">

const props = defineProps({
  id:{
    type:[String,Number],
    required:true,
  }
})

const isLoading = ref(true);

const form = reactive({
  auth:{
    domain:null,
    secret_bash:null,
  }
})

onMounted(async ()=>{
  await loadIntegration()
})

const copyFieldRef = ref()

const loadIntegration = async () => {

  isLoading.value = true;

  try {
    const response = await tenantClientFetch('/modules/widgets/integration/bitrix',{
      method:'GET',
      query:{
        id:toRaw(props.id)
      }
    })

    Object.assign(form.auth,response.payload)

    isLoading.value = false;

  }catch (e) {
    isLoading.value = true;
  }
}

const copyField = async val => {
  try {
    copyFieldRef.value.value = val;
    copyFieldRef.value.select()
    document.execCommand('copy')
    await  useNuxtApp().$notify.success('Вы успешно скопировали данные')
  }catch (e) {
    useNuxtApp().$notify.warn('Произошла ошибка при копировании данных')
  }
}

</script>

<template>
<div>
  <input class="fixed top-[-9999999px]"  ref="copyFieldRef"/>
  <div v-if="isLoading" class="grid grid-cols-4 gap-4">
    <Skeleton v-for="o in 16" height="50px"/>
  </div>
  <div v-if="!isLoading" class="rounded-lg grid grid-cols-2 gap-2">
    <div class="col-span-2 mt-2">
      <h4 class="font-semibold mb-2">Данные для входа в приложение Битрикс24 </h4>
    </div>
    <div class="flex flex-col gap-2">
      <label class="text-sm text-#637381">Домен</label>
      <div class="relative overflow-hidden">
        <InputText readonly class="w-full input" v-model="form.auth.domain"/>
        <button @click="copyField(form.auth.domain)" class="absolute top-0 right-0 bg-#6366F1 text-white h-full px-[3rem] rounded-r-lg">
          Скопировать
        </button>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <label class="text-sm text-#637381">Ключ приложения</label>
      <div class="relative">
        <InputText readonly class="w-full input" v-model="form.auth.secret_bash"/>
        <button @click="copyField(form.auth.secret_bash)" class="absolute top-0 right-0 bg-#6366F1 text-white h-full px-[3rem] rounded-r-lg">
          Скопировать
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>

</style>