<template>
  <div class="relative border rounded-xl">
    <div class="relative w-full">
      <div class="bg-white rounded-xl p-4 relative hover:shadow-md transition-all">
        <div class="flex justify-between items-center p-4 pt-0 min-h-[4rem] mb-4 gap-2">
          <div class="flex flex-col">
            <h3 class="text-lg text-left font-semibold">{{ house.name }}</h3>
            <p class="text-sm text-#637381 whitespace-nowrap" v-if="house.address && house.model === 'Item'">
              <template v-if="house.address.street">
                {{ house.address.street }}
              </template>
              <template v-if="house.address.house">
                {{ house.address.house }}
              </template>
            </p>
          </div>
          <InputSwitch v-model="house.isCheck"/>
        </div>
        <div class="mb-2.5 w-full h-auto">
          <div
              class="w-full min-h-[10rem] rounded-md bg-cover bg-no-repeat bg-center bg-#F9F9F9 relative p-2 flex justify-center"
              :style="`background: url(${object.img}), lightgray 50% / cover no-repeat`">

          </div>
        </div>
        <div class="flex justify-between gap-1">
        <span class="text-sm text-#637381" v-if="house.numberOfApartments !== 0">{{ object.numberOfApartments }} <span>{{
            masterString(object.numberOfApartments, ['помещение', 'помещения', 'помещений'])
          }}</span></span>
        </div>
        <p class="w-full h-px bg-#E8EAEE absolute top-20 left-0 right-0"></p>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  object: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['updateIsCheck'])

const house = ref({});

const checkOut = () => {
  house.value.isCheck = true;
}

onBeforeMount(() => {
  Object.assign(house.value, toRaw(props.object))
})

watch(() => [props.object], () => {
  Object.assign(house.value, toRaw(props.object))
})


watch(() => [house.value.isCheck], () => {
  if (house.value.isCheck) {
    emit('updateIsCheck', {_event: 'append', house: toRaw(house.value)})
    return;
  }

  emit('updateIsCheck', {_event: 'describe', house: toRaw(house.value)})
})


</script>