<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1098 549"><path fill="#C8313E" d="M0 0h1098v549H0z"/><path fill="#4AA657" d="M0 366h1098v183H0z"/><path fill="#FFF" d="M0 0h122v549H0z"/><g id="b"><g id="a" fill="#C8313E" fill-rule="evenodd"><path d="M4 0h3v1h1v1h1v1h1v1h1v1h-1v1H9v1H8v1H7v1H6v1H5V9H4V8H3V7H2V6H1V5H0V4h1V3h1V2h1V1h1zm1 2h1v1h1v1h1v1H7v1H6v1H5V6H4V5H3V4h1V3h1zm0 2h1v1H5zM0 1h1v1H0zm0 6h1v1H0zm11-7h.6v2H11zm0 7h.6v2H11zM2 9h1v1h1v1h1v1H4v1H3v1H2v-1H1v-1H0v-1h1v-1h1zm0 2h1v1H2zm6-2h1v1h1v1h1v1h-1v1H9v1H8v-1H7v-1H6v-1h1v-1h1zm0 2h1v1H8zm-8 4h1v1H0zm11-1h.6v2H11zM0 18h1v-1h1v-1h1v-1h1v-1h1v-1h1v1h1v1h1v1h1v1h1v1h1v1h.6v4H11v1h-1v1H9v1H8v1H7v1H6v2.6H4V30H3v-1H2v-1H1v-1H0v-3h1v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1H8v-1H7v-1H4v1h2v1H5v1H4v1H3v-1H2v-1H1v-1H0zm0 4h1v1H0zm11 3h.6v1H11zm-2 2h1v1h1v1h.6v1.6H11V30h-1v-1H9zm-2 3h1v.6H7z" transform="scale(5.30435 9)"/></g><use xlink:href="#a" transform="matrix(-1 0 0 1 122 0)"/></g><use xlink:href="#b" transform="matrix(1 0 0 -1 0 549)"/></svg>
</template>

<script>
export default {
  name: "AppBelarusFlag"
}
</script>

<style scoped>

</style>