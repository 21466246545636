import {defineStore} from "pinia";

export const useWidgetIntegrationStore = defineStore('widget_integration_store', {
    state: () => (
        {
            widget_id:''
        }
    ),
    getters: {
        getWidgetId: (state) => {
            return state.widget_id;
        }
    },

    actions: {
        setWidgetId(widgetId) {
            this.widget_id = widgetId;
        },
    },
    persist:{
        storage:persistedState.cookies
    },
})