<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.25" width="23.5" height="23.5" rx="1.75" fill="white" stroke="#F5F5F5" stroke-width="0.5"/>
    <mask id="mask0_5242_71028" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect x="0.25" y="0.25" width="23.5" height="23.5" rx="1.75" fill="white" stroke="white" stroke-width="0.5"/>
    </mask>
    <g mask="url(#mask0_5242_71028)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16H24V8H0V16Z" fill="#0C47B7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 24H24V16H0V24Z" fill="#E53B35"/>
    </g>
  </svg>
</template>
